import {useEffect, useState} from "react";
import LoadingError from "../../components/LoadingError";
import LoadingSpinner from "../../components/LoadingSpinner";
import NavBar from "../../components/NavBar";
import {IoCar, IoCheckmark, IoCheckmarkCircle, IoCloseCircle, IoPencil} from "react-icons/io5";

import "./VehiclesManagement.css"
import {useUserInfo} from "../../useUserInfo";
import toHtmlInputDate from "../../components/functions/toHtmlInputDate";

const VehiclesManagement = () => {

    const { userInfo } = useUserInfo()

    const [loadingError, setLoadingError] = useState(false)
    const [errorType, setErrorType] = useState()
    const [isLoading, setIsLoading] = useState(true)

    const [editing, setEditing] = useState(false)
    const [vehicles, setVehicles] = useState([])

    const vehicleStatusRetriever = async (vehicle) => {
        try {
            const response = await fetch(process.env.REACT_APP_BACKEND_URL + "/vehicles", {
                method: "GET",
                credentials: "include",
            })

            const data = await response.json()
            setVehicles(data.vehicles)
            setIsLoading(false)

        } catch (e) {
            setLoadingError(true)
            console.log(e)
        }

    }

    const saveChanges = async () => {
        try {

            setIsLoading(true)

            for (const vehicle of vehicles) {

                vehicle.insurance = new Date(vehicle.insurance)
                vehicle.stamp = new Date(vehicle.stamp)
                vehicle.revision = new Date(vehicle.revision)
            }

            const response = await fetch(process.env.REACT_APP_BACKEND_URL + "/vehicles", {
                method: "PUT",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({vehicles})
            })

            if (response.status !== 200) {
                const responseJson = await response.json()
                setErrorType("Il server non ha accettato tale richiesta; ecco il messaggio nel dettaglio: " + responseJson.message)
                setLoadingError(true)
            }


            console.log(JSON.stringify({vehicles}))

            setIsLoading(false)


        } catch (e) {
            setLoadingError(true)
            console.log(e)
        }
    }

    const vehicleStatus = (vehicle) => {
        let status = "ok"

        //30 g
        const days = 30 * 24 * 60 * 60 * 1000
        if (new Date(vehicle.stamp) - new Date() < days || new Date(vehicle.insurance) - new Date() < days || new Date(vehicle.revision) - new Date() < days) {
            status = "warning"
        }

        if (new Date(vehicle.stamp) - new Date() < 0 || new Date(vehicle.insurance) - new Date() < 0 || new Date(vehicle.revision) - new Date() < 0) {
            status = "expired"
        }

        return status
    }

    useEffect(() => {
        vehicleStatusRetriever().then(() => {})
    }, []);

    useEffect(() => {
        if (!isLoading && !editing) {
            saveChanges()
        }
    }, [editing]);

    return (
        (loadingError) ? <LoadingError errorDescription={errorType}/> :
        (isLoading) ? <LoadingSpinner /> :
        <div className="mainContainer vehiclesManagement">
            <NavBar />
            {vehicles.map((vehicle) => (
            <div key={vehicle.id} className="vehicleContainer">
                <div className="iconsContainer" >
                    <IoCar size={50} />
                    {(vehicleStatus(vehicle) === "expired") ? <IoCloseCircle color="red" size={30} /> : <IoCheckmarkCircle color={(vehicleStatus(vehicle) === "ok" ? "green" : "orange")} size={30} />}
                </div>
                <div className="infoContainer" >
                    {(editing) ?
                        <div className="form__group">
                        <input className="form__field" type="text" value={vehicle.name} placeholder="Nome"
                           onChange={(e) => {
                            vehicle.name = e.target.value
                            setVehicles([...vehicles])
                        } } />
                        <label className="form__label">Nome</label>
                        </div>
                            :
                        <h2>{vehicle.name}</h2>}
                    {(editing) ?
                        <div className="form__group">
                        <input className="form__field" type="text" value={vehicle.plate}
                           onChange={(e) => {
                            vehicle.plate = e.target.value
                            setVehicles([...vehicles])
                        } } />
                        <label className="form__label">Targa</label>
                        </div>
                            :
                        <p><b>Targa:</b> {vehicle.plate}</p>}
                    <p><b>Assicurazione:</b> {(editing) ?
                        <input type="date" value={toHtmlInputDate(new Date(vehicle.insurance))}
                           className="form__field"
                           onChange={(e) => {
                            vehicle.insurance = new Date(e.target.value)
                            setVehicles([...vehicles])
                        } } />
                            :
                        new Date(vehicle.insurance).toLocaleDateString("it-IT", {year: "numeric", month: "2-digit", day: "2-digit"}) }</p>
                    <p><b>Bollo:</b> {(editing) ?
                        <input type="date" value={toHtmlInputDate(new Date(vehicle.stamp))}
                           className="form__field"
                           onChange={(e) => {
                            vehicle.stamp = new Date(e.target.value)
                            setVehicles([...vehicles])
                        } } />
                            :
                        new Date(vehicle.stamp).toLocaleDateString("it-IT", {year: "numeric", month: "2-digit", day: "2-digit"}) }</p>
                    <p><b>Revisione:</b> {(editing) ?
                        <input type="date" value={toHtmlInputDate(new Date(vehicle.revision))}
                            className="form__field"
                           onChange={(e) => {
                            vehicle.revision = new Date(e.target.value)
                            setVehicles([...vehicles])
                        } } />
                            :
                        new Date(vehicle.revision).toLocaleDateString("it-IT", {year: "numeric", month: "2-digit", day: "2-digit"}) }</p>
                </div>
                {(userInfo.isAdmin) ? ((editing) ? <IoCheckmark onClick={() => setEditing(false)} size={20} /> : <IoPencil onClick={() => setEditing(true)} size={20} />) : null}
            </div>
            ))}
        </div>

    );
}

export default VehiclesManagement;