import {useEffect, useState} from "react";
import NavBar from "../../../components/NavBar";
import LoadingError from "../../../components/LoadingError";
import LoadingSpinner from "../../../components/LoadingSpinner";
import {useNavigate, useParams} from "react-router-dom";
import {IoCalendarClearOutline, IoSyncSharp, IoTime, IoTrashBin} from "react-icons/io5";
import {MdDescription} from "react-icons/md";

import './Event.css'
import {useUserInfo} from "../../../useUserInfo";
import {IoLogoWhatsapp} from "react-icons/io5";

const Event = () => {

    const navigate = useNavigate()
    const { userInfo } = useUserInfo()

    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const [event, setEvent] = useState({
        id: 0,
        name: "",
        description: "",
        beginning: new Date(),
        ending: new Date(),
        typeid: 0,
        userid: 0,
        notifications: []
    })
    const [events, setEvents] = useState([])

    const { id} = useParams()
    const eventInfoRetriever = async () => {
        try {
            const res = await fetch(process.env.REACT_APP_BACKEND_URL + '/calendar/' + id, {
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                }
            })

            let data = await res.json()

            setEvent(data.event)
            setLoading(false)

        } catch (e) {
            setErrorMessage(e.message)
            setError(true)
        }
    }

    const allEventsRetriever = async () => {
        try {
            const res = await fetch(process.env.REACT_APP_BACKEND_URL + '/calendar/', {
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                }
            })

            let data = await res.json()

            setEvents(data.events)
            setLoading(false)

        } catch (e) {
            setErrorMessage(e.message)
            setError(true)
        }
    }

    const deleteEvent = async () => {
        try {

            //Also backend check if the user is admin
            if (!userInfo.isAdmin) {
                alert("Non sei autorizzato a cancellare questo evento")
                return
            }

            if (!window.confirm("Sei sicuro di voler eliminare questo evento?")) {
                return
            }

            const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/calendar/' + id, {
                method: 'DELETE',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                }
            })

            if (response.status !== 200) {
                const data = await response.json()
                setErrorMessage(data.message)
                setError(true)
            }

            navigate('/calendar')

        } catch (e) {
            setErrorMessage(e.message)
            setError(true)
        }
    }

    useEffect(() => {
        eventInfoRetriever().then(() => {})
        allEventsRetriever().then(() => {})
    }, []);

    return (
        (error) ? <LoadingError message={errorMessage} /> : (loading) ? <LoadingSpinner /> :
            <div className="mainContainer">

                <NavBar/>

                <h1>Evento n°{event.id}</h1>

                <div className="infoAndPhotosContainer">
                    <div className="infoContainer">
                        {new Date(event.ending).getTime() < new Date().getTime() ?
                            <div className="statusIndicator green">Completato</div>
                            :
                            new Date(event.beginning) < new Date() ?
                                <div className="statusIndicator red">In corso</div>
                                :
                                <div className="statusIndicator blue">In Programma</div>
                        }
                        <p><IoCalendarClearOutline/><b>&nbsp;{event.name}</b></p>
                        <p><MdDescription/>&nbsp;{event.description}</p>
                        <p><IoTime/><b> Da: </b> {new Date(event.beginning).toLocaleString({timeZone: "it-IT"})}</p>
                        <p><IoTime/><b> A: </b> {new Date(event.ending).toLocaleString({timeZone: "it-IT"})}</p>
                    </div>
                    {(event.notifications.length > 0)  && <div className="notificationContainer">

                        <h2>Notifiche</h2>

                        <table>
                            <thead>
                            <tr>
                                <th>Tipo</th>
                                <th>Utente</th>
                                <th>Timestamp</th>
                            </tr>
                            </thead>
                            <tbody>
                            {event.notifications.map((notification, index) => {
                                return <tr key={index}>
                                    <td><p className="table_elements"><IoLogoWhatsapp/></p></td>
                                    <td><p className="table_elements">{notification.name} {notification.surname}</p>
                                    </td>
                                    <td><p
                                        className="table_elements">{new Date(notification.send).toLocaleString('it-IT', {
                                        weekday: "long",
                                        day: "numeric",
                                        month: "long",
                                        year: "numeric",
                                        hour: "numeric",
                                        minute: "numeric"
                                    })}</p></td>
                                </tr>
                            })}
                            </tbody>
                        </table>
                    </div>
                    }
                </div>

                {(event.typeid === 1 && new Date(event.ending).getTime() > new Date().getTime() && false) ? //temporary disable
                    <div className="onCallExchange">
                        <h2>Richiedi Scambio</h2>
                        <select className="form__field">
                            {   //Take only the event where userid is the same as the logged user
                                events.filter(e => e.typeid === 1 && e.userid === 3).map(e => {
                                    return <option key={e.id}
                                                   value={e.id}>{`${new Date(e.beginning).toLocaleDateString("it-IT")} - ${new Date(e.ending).toLocaleDateString("it-IT")}`}</option>
                                })}
                        </select>
                        <button className="button" onClick={() => {
                            alert("Funzionalità non disponibile nella versione di valutazione");
                        }}>
                            <IoSyncSharp/>
                            &nbsp;
                            Scambia
                        </button>
                    </div>
                    :
                    null
                }

                <button className="button delete" onClick={deleteEvent}>
                    <IoTrashBin size={24}/>
                    &nbsp;
                    Elimina
                </button>

            </div>
    )
}

export default Event