import React from "react";
import { useUserInfo } from "./useUserInfo"
import { Navigate, Outlet } from "react-router-dom";


const RequireAuth = () => {

    const { userInfo } = useUserInfo();

    if (userInfo.isAuth === false) {
        console.log("Redirecting to login")
        return <Navigate to="/login" />;
    }

    return <Outlet />;
}

export default RequireAuth;