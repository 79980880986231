import toHtmlInputDate from "./toHtmlInputDate";

const addLabourLineIfLastFilled = (labour, setLabour) => {

    if (labour.length === 0) {
        setLabour([{ date: toHtmlInputDate(new Date()), users: [], vehicles: [] }])
    }

    // if last worker of any labour is filled, add a new empty worker
    labour.forEach((val, idx) => {
        if (val.users.length === 0) {
            let newLabour = [...labour]
            newLabour[idx].users.push({ id: "", name: "", surname: "", hours: 0, minutes: 0 })
            setLabour(newLabour)
        }
        else if (val.users[val.users.length - 1].id !== "" || val.users[val.users.length - 1].hours !== 0 || val.users[val.users.length - 1].minutes !== 0) {
            let newLabour = [...labour]
            newLabour[idx].users.push({ id: "", name: "", surname: "", hours: 0, minutes: 0 })
            setLabour(newLabour)
        }
    })
    // if last vehicle of any labour is filled, add a new empty vehicle
    labour.forEach((val, idx) => {
        if (val.vehicles.length === 0) {
            let newLabour = [...labour]
            newLabour[idx].vehicles.push({ id: "", name: "", plate: "", hours: 0, minutes: 0 })
            setLabour(newLabour)
        }
        else if (val.vehicles[val.vehicles.length - 1].id !== "" || val.vehicles[val.vehicles.length - 1].hours !== 0 || val.vehicles[val.vehicles.length - 1].minutes !== 0) {
            let newLabour = [...labour]
            newLabour[idx].vehicles.push({ id: "", name: "", plate: "", hours: 0, minutes: 0 })
            setLabour(newLabour)
        }
    })
}

export default addLabourLineIfLastFilled;