const addMaterialLineIfLastFilled = (materials, setMaterials) => {

    //if last material is filled, add a new empty material
    if (materials.length === 0) {
        setMaterials([{ name: "", quantity: "", unit: "n", checked: 0 }]);
    }
    else if (materials[materials.length - 1].name !== "" || materials[materials.length - 1].quantity !== "" || materials[materials.length - 1].unit !== "n") {
        setMaterials([...materials, { name: "", quantity: "", unit: "n", checked: 0 }]);
    }

}

export default addMaterialLineIfLastFilled;