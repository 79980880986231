import React, { useEffect, useState} from "react";
import { useNavigate, useParams } from "react-router-dom";
import NavBar from "../../components/NavBar";
import LoadingSpinner from "../../components/LoadingSpinner";
import LoadingError from "../../components/LoadingError";
import "./Edit.css"
import inputFieldChecker from "../../functions/inputFieldChecker";
import InfoForm from "../../components/Add/InfoForm";
import ImageForm from "../../components/Add/ImageForm";
import addLabourLineIfLastFilled from "../../components/functions/addLabourLineIfLastFilled";
import addMaterialLineIfLastFilled from "../../components/functions/addMaterialLineIfLastFilled";
import addEquipmentLineIfLastFilled from "../../components/functions/addEquipmentLineIfLastFilled";
import MaterialTable from "../../components/Add/MaterialTable";
import EquipmentTable from "../../components/Add/EquipmentTable";
import DayTable from "../../components/Add/DayTable";



const Edit = () => {

    const { id } = useParams();

    //Navigation
    const navigate = useNavigate();

    //Page state
    const [loadingError, setLoadingError] = React.useState(false);
    const [loadingMessage, setLoadingMessage] = useState("");
    const [errorType, setErrorType] = React.useState("");
    const [isLoading, setIsLoading] = React.useState(true);
    //Page data
    const [customer, setCustomer] = React.useState("");
    const [description, setDescription] = React.useState("");
    const [notes, setNotes] = React.useState("");
    const [completed, setCompleted] = React.useState(false);
    const [onCall, setOnCall] = React.useState(false);
    const [loadedImages, setLoadedImages] = React.useState([]);
    const [newImages, setNewImages] = React.useState([]);
    const [users, setUsers] = React.useState([]);
    const [vehicles, setVehicles] = React.useState([]);
    const [materials, setMaterials] = React.useState([]); //Already initialized in useEffect
    const [equipments, setEquipments] = React.useState([]); //Already initialized in useEffect
    const [equipmentsList, setEquipmentsList] = React.useState([]); //Already initialized in useEffect
    const [labour, setLabour] = React.useState([{ date: "", users: [], vehicles: [] }]); //users and vehicles are initialized in useEffect (impossible to remove all)

    //Structure for components
    const infoFormValues = {customer, description, notes, completed, onCall}
    const infoFormSetValues = {setCustomer, setDescription, setNotes, setCompleted, setOnCall}

    const imageFormValues = {loadedImages, newImages}
    const imageFormSetValues = {setLoadedImages, setNewImages}

    const materialTableValues = {materials}
    const materialTableSetValues = {setMaterials}

    const equipmentTableValues = {equipments, equipmentsList}
    const equipmentTableSetValues = {setEquipments}

    const dayTableValues = {labour, users, vehicles}
    const dayTableSetValues = {setLabour}


    useEffect(() => {
        fetch(process.env.REACT_APP_BACKEND_URL + "/work/" + id, {
            method: "GET",
            credentials: "include",
        })
            .then((response) => {
                if (response.status === 200) {
                    response.json().then((data) => {
                        setCustomer(data.customer);
                        setDescription(data.description);
                        setNotes(data.note);
                        setCompleted(data.completed === 1);
                        setOnCall(data.oncall === 1);
                        setMaterials(data.materials);
                        setEquipments(data.equipments);
                        //make labour dates readable
                        data.labour.forEach((val) => {
                            val.date = new Date(val.date).toISOString().split('T')[0]
                        })
                        setLabour(data.labour);
                    });
                }
                else if (response.status === 404) {
                    setLoadingError(true)
                }
            })
            .catch(() => {
                setErrorType("Network error")
                setLoadingError(true)
            });



        fetch(process.env.REACT_APP_BACKEND_URL + "/users", {
            method: "GET",
            credentials: "include",
        })
            .then((response) => {
                if (response.status === 200) {
                    response.json().then((data) => {
                        setUsers(data.users);
                    });
                }
            })
            .catch(() => {
                setErrorType("Network error")
                setLoadingError(true);
            });

        fetch(process.env.REACT_APP_BACKEND_URL + "/vehicles", {
            method: "GET",
            credentials: "include",
        })
            .then((response) => {
                if (response.status === 200) {
                    response.json().then((data) => {
                        setVehicles(data.vehicles);
                    });
                }
            })
            .catch(() => {
                setErrorType("Network error")
                setLoadingError(true);
            });

        fetch(process.env.REACT_APP_BACKEND_URL + "/equipments", {
            method: "GET",
            credentials: "include",
        })
            .then((response) => {
                if (response.status === 200) {
                    response.json().then((data) => {
                        console.log(data.equipments)
                        setEquipmentsList(data.equipments);
                    });
                }
            })
            .catch(() => {
                setErrorType("Network error")
                setLoadingError(true);

        })

        fetch(process.env.REACT_APP_BACKEND_URL + "/works/" + id + "/images", {
            method: "GET",
            credentials: "include",
        })
            .then((response) => {
                if (response.status === 200) {
                    response.json().then((data) => {
                        setLoadedImages(data.images);
                    });
                }
            })
            .catch(() => {
                setErrorType("Network error")
                setLoadingError(true);
            });

    }, []);

    useEffect(() => {
        if (users.length > 0 && vehicles.length > 0 && equipmentsList.length > 0) {
            setIsLoading(false);
        }
    }, [users, vehicles, equipmentsList]);

    useEffect(() => {
        addLabourLineIfLastFilled(labour, setLabour)
    }, [labour]);

    useEffect(() => {
        addMaterialLineIfLastFilled(materials, setMaterials)
    }, [materials]);

    useEffect(() => {
        addEquipmentLineIfLastFilled(equipments, setEquipments)
    }, [equipments]);

    const save = async (e) => {


        // meaning that the default action that belongs to the event will not occur.
        // In this case do not send as a normal www-form-urlencoded form, but as a json with fetch
        e.preventDefault();

        const inputField = {
            customer: customer,
            description: description,
            notes: notes,
            completed: completed,
            oncall: onCall,
            materials: materials,
            equipments: equipments,
            labour: labour,
            vehicles: vehicles,
        }

        let validatedInput

        try {
            validatedInput = inputFieldChecker(inputField)
            console.log(validatedInput)
        } catch (error) {
            alert("Errore nel salvataggio del rapportino: nel dettaglio " + error.message)
            return
        }

        setIsLoading(true)


        try {
            const response = await fetch(process.env.REACT_APP_BACKEND_URL + "/works/" + id, {
                method: "PUT",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(validatedInput),
            })

            if (response.status === 200) {
                await uploadImages(id);
                if (!loadingError) navigate("/home");
            } else {
                setErrorType("Errore nel salvataggio del rapportino. Nel dettaglio " + response.message.json())
                setLoadingError(true)
            }
        } catch (err) {
            setErrorType("Errore di connessione; Nel dettaglio " + err.message)
            setLoadingError(true)
        }
    }

    const uploadImages = async (workId) => {

        try {

            for (const image of newImages) {

                setLoadingMessage("Caricamento immagine " + (newImages.indexOf(image) + 1) + " di " + newImages.length)

                let formData = new FormData();
                formData.append("photo", image);
                const response = await fetch(process.env.REACT_APP_BACKEND_URL + "/works/" + workId + "/images", {
                    method: "POST",
                    credentials: "include",
                    body: formData
                })
                if (response.status !== 200) {
                    setErrorType("Errore nel caricamento di una immagine. Nel dettaglio " + response.message)
                    setLoadingError(true)
                }

            }
        } catch (err) {
            setErrorType("Errore di connessione; Nel dettaglio " + err.message)
            setLoadingError(true)
        }
    }

    const deleteImage = (image) => {
        // Take the name from the url, removing the .format
        let name = image.url.split("/").pop().split(".")[0]
        fetch(process.env.REACT_APP_BACKEND_URL + "/works/" + id + "/images/" + name, {
            method: "DELETE",
            credentials: "include",
        })
            .then((response) => {
                if (response.status === 200) {
                    response.json().then((data) => {
                        let newImages = [...loadedImages]
                        // Remove current row
                        newImages.splice(newImages.indexOf(image), 1)
                        setLoadedImages(newImages)
                    });
                }
            })
            .catch(() => {
                setErrorType("Network error")
                setLoadingError(true)
            });
    }

    return (
        loadingError ? <LoadingError errorDescription={errorType} /> :
            isLoading ? <LoadingSpinner message={loadingMessage}/> :
                <div className="mainContainer">
                    <NavBar />

                    <h1>Rapporto N° {id}</h1>

                    <InfoForm values={infoFormValues} setValues={infoFormSetValues} />

                    <ImageForm values={imageFormValues} setValues={imageFormSetValues} functions={{deleteImage}} />

                    <MaterialTable values={materialTableValues} setValues={materialTableSetValues} />

                    <EquipmentTable values={equipmentTableValues} setValues={equipmentTableSetValues} />

                    <DayTable values={dayTableValues} setValues={dayTableSetValues} />

                    <button className="button" style={{ width: "80%", maxWidth: 200, margin: 20 }} onClick={save}>
                        Salva
                    </button>
                </div>
    )

};

export default Edit;