import { useState } from "react";
import { useNavigate } from "react-router-dom";
import  {useUserInfo}  from "../../useUserInfo";
import LoadingError from "../../components/LoadingError";
import LoadingSpinner from "../../components/LoadingSpinner";

import "./Login.css"
import loginEasterEgg from "../../components/EasterEgg/Login";


const Login = () => {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [domain, setDomain] = useState(process.env.REACT_APP_BUSINESS_DOMAIN);

    const [isLoading, setIsLoading] = useState(false);
    const [loadingError, setLoadingError] = useState(false);
    const [errorDescription, setErrorDescription] = useState("");
    const [wrongCredentials, setWrongCredentials] = useState(false);

    const availableDomain = ["SSO (" + process.env.REACT_APP_BUSINESS_DOMAIN + ")", "local"];

    const { setUserInfo } = useUserInfo();

    const navigate = useNavigate();

    const login = async (e) => {

        e.preventDefault();
        setIsLoading(true)

        loginEasterEgg(email, password)

        try {


            const loginUrl= process.env.REACT_APP_BACKEND_URL + "/login" + ((domain === "local") ? "" : "/sso");

            /*
            const isRegexOk = new RegExp("^[a-zA-Z0-9._%+-]" + process.env.REACT_APP_BUSINESS_DOMAIN).test(email);
            if (!isRegexOk) {
                setIsLoading(false)
                setWrongCredentials(true)
                return;
            }

             */

            const response = await fetch(loginUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "include",
                body: JSON.stringify({
                    username: email,
                    password: password,
                }),
            })

            if (response.status === 200) {

                let responseJson = await response.json();

                responseJson.isAuth = true;
                setUserInfo(responseJson);

                if (responseJson.name === "" || responseJson.surname === "" || responseJson.name == null || responseJson.surname == null) {
                    setIsLoading(false)
                    navigate("/setname");
                    return;
                }

                setIsLoading(false)
                navigate("/home");

            } else if (response.status === 401) {
                setIsLoading(false)
                setWrongCredentials(true)
            } else {
                setErrorDescription("Errore sconosciuto")
                setLoadingError(true)
            }
        } catch (error) {
            setErrorDescription("Errore di rete")
            setLoadingError(true)
        }
    }


    return (

        (loadingError) ? <LoadingError errorDescription={errorDescription} /> :
        (isLoading) ? <LoadingSpinner /> :

        <div className="mainLoginContainer">
            <div className="loginImageContainer"/>
            <div className="loginFormContainer">
                <form onSubmit={login} >
                    <h1 className="loginH1">Rapportini</h1>
                    <div className="form__group field" >
                        <input
                            type="text"
                            autoCapitalize='none'
                            id="email"
                            className="form__field"
                            placeholder="Email"
                            autoComplete="off"
                            required
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                        />
                        <label className="form__label">Email</label>
                    </div>
                    <div className="form__group field">
                        <input
                            type="password"
                            id="password"
                            className="form__field"
                            placeholder="Password"
                            required
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                        />
                        <label className="form__label">Password</label>
                    </div>

                    <div className = "form__group field">
                        <label className="form__label">Dominio</label>
                        <select name="domain" className="form__field" required
                                onChange={
                                    (e) => {setDomain(e.target.value)}
                                }
                                value={domain}
                        >
                            {availableDomain.map((domain) => {
                                return <option value={domain}>{domain}</option>
                            })}
                        </select>
                    </div>


                    <input type="submit" value="Login" className="button" style={{marginTop: 35}} />
                    </form>
                {wrongCredentials && <p style={{color: "red", textAlign: "center", marginTop: 20}}>Credenziali errate</p>}
                <p className="customFooter">Portale riservato al personale di {process.env.REACT_APP_BUSINESS_NAME}. Ogni tentativo di abuso verrà riportato e punito a norma di legge </p>
            </div>
        </div>
    );
}

export default Login;