const addEquipmentLineIfLastFilled = (equipments, setEquipments) => {

    if (equipments.length === 0) {
        setEquipments([{ id: "", hours: 0, minutes: 0 }]);
    }
    else if (equipments[equipments.length - 1].id !== "" || equipments[equipments.length - 1].hours !== 0 || equipments[equipments.length - 1].minutes !== 0) {
        setEquipments([...equipments, { id: "", hours: 0, minutes: 0 }]);
    }
}

export default addEquipmentLineIfLastFilled;