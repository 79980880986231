import React from "react";
import "./SearchBox.css";
import { IoSearchOutline } from "react-icons/io5";
import toHtmlInputDate from "../functions/toHtmlInputDate";

export default function SearchBox({values, setValues, refresh}) {

    const searchIfEnter = (e) => {
        e.key === "Enter" && refresh()
    }

    return (
        <div className="searchBoxContainer">
            <div className="searchBoxRow">
                <div className="elementFilter">
                    <p>Cliente</p>
                    <input
                        type="text"
                        value={values.client}
                        onChange={(e) => {
                            setValues.setClient(e.target.value)
                        }}
                        onKeyDown={searchIfEnter}
                    />
                </div>

                <div className="elementFilter">
                    <p>Da</p>
                    <input
                        type="date"
                        value={toHtmlInputDate(values.start)}
                        onChange={(e) => {
                            setValues.setStart(new Date(e.target.value))
                        }}
                    />
                </div>

                <div className="elementFilter" >
                    <p>A</p>
                    <input
                        type="date"
                        value={toHtmlInputDate(values.end)}
                        onChange={(e) => {
                            setValues.setEnd(new Date(e.target.value))
                        }}
                    />
                </div>

                <div className="elementFilter">
                    <p>Contabilizzato</p>

                    {/* Add a input with selextion si, no, indifferente*/}
                    <select
                        value={values.processed}
                        onChange={(e) => {
                            setValues.setProcessed(e.target.value)
                        }}
                    >
                        <option value="indifferente">Indifferente</option>
                        <option value="1">Si</option>
                        <option value="0">No</option>
                    </select>

                </div>

                {/* Search button */}
                <div className="elementFilter">

                    {/*align to other element*/}
                    <p style={{visibility: "hidden"}}>Cerca</p>

                    <button className="searchButton" onClick={() => {
                        refresh()
                    }}><IoSearchOutline ></IoSearchOutline></button>
                </div>

            </div>

            <div className="searchBoxRow">

                <div className="elementFilter">
                    <p>Descrizione / Note</p>
                    <input
                        type="text"
                        value={values.description}
                        style={{width: "100%"}}
                        onChange={(e) => {
                            setValues.setDescription(e.target.value)
                        }}
                        onKeyDown={searchIfEnter}
                    />

                </div>

            </div>

        </div>
    )
}