import React from "react";
import {useNavigate} from "react-router-dom";

const UserInfoContext = React.createContext({});

const UserInfoProvider = ({ children }) =>
{
    const [userInfo, setUserInfo] = React.useState({});
    const navigate = useNavigate();

    const retrieveUserInfo = async () => {
        try {

            const res = await fetch(process.env.REACT_APP_BACKEND_URL + '/whoami', {
                method: 'GET',
                credentials: 'include',
            })
            if (res.status === 200) {
                res.json().then((data) => {
                    data.isAuth = true;
                    setUserInfo(data);
                });
            }
            else {
                setUserInfo({isAuth: false});
                navigate("/login");
            }
        } catch (e) {
            setUserInfo({isAuth: false})
            console.log(e);
        }
    }

    React.useEffect(() => {
        retrieveUserInfo().then(() => {});
    }, []);

    return(
        <UserInfoContext.Provider value={{userInfo: userInfo, setUserInfo: setUserInfo}}>
            { children }
        </UserInfoContext.Provider>
    )
}

export default UserInfoProvider;

export const useUserInfo = () => React.useContext(UserInfoContext);

