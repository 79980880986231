import {IoClose} from "react-icons/io5";
import React from "react";
import toHtmlInputDate from "../functions/toHtmlInputDate";

export default function DayTable({values, setValues, functions}) {
    return (
    <div style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "50px"
    }}>
    <h2>Giornate</h2>
    {
        values.labour.map((val, index) => {
            return (
                <div key={val.id} style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "100%",
                    maxWidth: "600px",
                    gap: "50px"
                }}>
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        width: "80%",
                        maxWidth: 200
                    }}>
                        <input type="date" name="date" data-id={index} id={`date-${index}`} className="form__field"
                               placeholder="Data"
                               value={values.labour[index].date}
                               onChange={(e) => {
                                   let newLabour = [...values.labour]
                                   newLabour[index].date = e.target.value
                                   setValues.setLabour(newLabour)
                               }}
                        />
                        <IoClose color={"grey"} size={30} style={{cursor: "pointer", marginLeft: 20}} onClick={() => {
                            if (values.labour.length === 1) {
                                setValues.setLabour([{
                                    date: toHtmlInputDate(new Date()),
                                    users: [{name: "", surname: "", hours: 0, minutes: 0}],
                                    vehicles: [{name: "", plate: "", hours: 0, minutes: 0}]
                                }])
                                return
                            }
                            let newLabour = [...values.labour]
                            // remove current row
                            newLabour.splice(index, 1)
                            setValues.setLabour(newLabour)
                        }}/>
                    </div>
                    <table style={{width: "100%"}}>
                        <thead>
                        <tr>
                            <th>Operatore</th>
                            <th>Ore</th>
                            <th>Minuti</th>
                            <th/>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            val.users.map((laborer, idx) => {
                                    return (
                                        <tr key={laborer.id}>
                                            <td style={{paddingInline: "10px"}}>
                                                <select name="id" data-id={idx} id={`id-${idx}`} className="form__field"
                                                        placeholder="Operatore" style={{minWidth: 150}}
                                                        defaultValue={""}
                                                        value={laborer.id}
                                                        onChange={(e) => {
                                                            let newLabour = [...values.labour]
                                                            newLabour[index].users[idx].id = e.target.value
                                                            setValues.setLabour(newLabour)
                                                        }}
                                                >
                                                    <option value={""}>Nessuno</option>
                                                    {
                                                        values.users && values.users.map((user) => {
                                                            return (
                                                                <option
                                                                    hidden={
                                                                        // if the user is already selected, hide it from the options
                                                                        val.users.filter((val) => parseInt(val.id) === parseInt(user.id)).length > 0
                                                                    }
                                                                    value={user.id}>{user.name} {user.surname}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </td>
                                            <td style={{paddingInline: "10px"}}>
                                                <select name="hours" data-id={idx} id={`hours-${idx}`}
                                                        className="form__field" placeholder="Ore"
                                                        style={window.innerWidth < 600 ? {
                                                            maxWidth: 80,
                                                            minWidth: 60
                                                        } : {maxWidth: 120}}
                                                        value={laborer.hours}
                                                        onChange={(e) => {
                                                            let newLabour = [...values.labour]
                                                            newLabour[index].users[idx].hours = e.target.value
                                                            setValues.setLabour(newLabour)
                                                        }}
                                                >
                                                    <option value={0}>0</option>
                                                    <option value={1}>1</option>
                                                    <option value={2}>2</option>
                                                    <option value={3}>3</option>
                                                    <option value={4}>4</option>
                                                    <option value={5}>5</option>
                                                    <option value={6}>6</option>
                                                    <option value={7}>7</option>
                                                    <option value={8}>8</option>
                                                    <option value={9}>9</option>
                                                    <option value={10}>10</option>
                                                    <option value={11}>11</option>
                                                    <option value={12}>12</option>
                                                </select>
                                            </td>
                                            <td style={{paddingInline: "10px"}}>
                                                <select name="minutes" data-id={idx} id={`minutes-${idx}`}
                                                        className="form__field" placeholder="Minuti"
                                                        style={window.innerWidth < 600 ? {maxWidth: 80} : {maxWidth: 120}}
                                                        value={laborer.minutes}
                                                        onChange={(e) => {
                                                            let newLabour = [...values.labour]
                                                            newLabour[index].users[idx].minutes = e.target.value
                                                            setValues.setLabour(newLabour)
                                                        }}
                                                >
                                                    <option value={0}>0</option>
                                                    <option value={30}>30</option>
                                                </select>
                                            </td>
                                            <td style={{paddingInline: "0px"}}>
                                                <IoClose color={"grey"} size={24} style={{cursor: "pointer"}}
                                                         onClick={() => {
                                                             if (val.users.length === 1) {
                                                                 let newLabour = [...values.abour]
                                                                 newLabour[index].users = [{
                                                                     name: "",
                                                                     surname: "",
                                                                     hours: 0,
                                                                     minutes: 0
                                                                 }]
                                                                 setValues.setLabour(newLabour)
                                                                 return
                                                             }
                                                             let newLabour = [...values.labour]
                                                             // remove current row
                                                             newLabour[index].users.splice(idx, 1)
                                                             setValues.setLabour(newLabour)
                                                         }}/>
                                            </td>
                                        </tr>
                                    )
                                }
                            )
                        }
                        </tbody>
                    </table>
                    <table style={{width: "100%"}}>
                        <thead>
                        <tr>
                            <th>Veicolo</th>
                            <th>Ore</th>
                            <th>Minuti</th>
                            <th/>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            val.vehicles.map((vehicle, idx) => {
                                    return (
                                        <tr key={vehicle.id}>
                                            <td style={{paddingInline: "10px"}}>
                                                <select name="id" data-id={idx} id={`id-${idx}`} className="form__field"
                                                        placeholder="Veicolo" style={{minWidth: 150}}
                                                    //set the default shown value to none of the options
                                                        defaultValue={0}
                                                        value={vehicle.id}
                                                        onChange={(e) => {
                                                            let newLabour = [...values.labour]
                                                            newLabour[index].vehicles[idx].id = e.target.value
                                                            setValues.setLabour(newLabour)
                                                        }}
                                                >
                                                    <option value={-1}>Nessuno</option>
                                                    {
                                                        values.vehicles && values.vehicles.map((vehicle) => {
                                                            return (
                                                                <option hidden={
                                                                    // if the vehicle is already selected, hide it from the options
                                                                    val.vehicles.filter((val) => parseInt(val.id) === parseInt(vehicle.id)).length > 0
                                                                }
                                                                        value={vehicle.id}>{vehicle.name} {vehicle.plate}
                                                                </option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </td>
                                            <td style={{paddingInline: "10px"}}>
                                                <select name="hours" data-id={idx} id={`hours-${idx}`}
                                                        className="form__field" placeholder="Ore"
                                                        style={window.innerWidth < 600 ? {
                                                            maxWidth: 80,
                                                            minWidth: 60
                                                        } : {maxWidth: 120}}
                                                        value={vehicle.hours}
                                                        onChange={(e) => {
                                                            let newLabour = [...values.labour]
                                                            newLabour[index].vehicles[idx].hours = e.target.value
                                                            setValues.setLabour(newLabour)
                                                        }}
                                                >
                                                    <option value={0}>0</option>
                                                    <option value={1}>1</option>
                                                    <option value={2}>2</option>
                                                    <option value={3}>3</option>
                                                    <option value={4}>4</option>
                                                    <option value={5}>5</option>
                                                    <option value={6}>6</option>
                                                    <option value={7}>7</option>
                                                    <option value={8}>8</option>
                                                    <option value={9}>9</option>
                                                    <option value={10}>10</option>
                                                    <option value={11}>11</option>
                                                    <option value={12}>12</option>
                                                </select>
                                            </td>
                                            <td style={{paddingInline: "10px"}}>
                                                <select name="minutes" data-id={idx} id={`minutes-${idx}`}
                                                        className="form__field" placeholder="Minuti"
                                                        style={window.innerWidth < 600 ? {maxWidth: 80} : {maxWidth: 120}}
                                                        value={vehicle.minutes}
                                                        onChange={(e) => {
                                                            let newLabour = [...values.labour]
                                                            newLabour[index].vehicles[idx].minutes = e.target.value
                                                            setValues.setLabour(newLabour)
                                                        }}
                                                >
                                                    <option value={0}>0</option>
                                                    <option value={30}>30</option>
                                                </select>
                                            </td>
                                            <td style={{paddingInline: "0px"}}>
                                                <IoClose color={"grey"} size={24} style={{cursor: "pointer"}}
                                                         onClick={() => {
                                                             if (val.vehicles.length === 1) {
                                                                 let newLabour = [...values.labour]
                                                                 newLabour[index].vehicles = [{
                                                                     id: "",
                                                                     name: "",
                                                                     plate: "",
                                                                     hours: 0,
                                                                     minutes: 0
                                                                 }]
                                                                 setValues.setLabour(newLabour)
                                                                 return
                                                             }
                                                             let newLabour = [...values.labour]
                                                             // remove current row
                                                             newLabour[index].vehicles.splice(idx, 1)
                                                             setValues.setLabour(newLabour)
                                                         }}/>
                                            </td>
                                        </tr>
                                    )
                                }
                            )
                        }
                        </tbody>
                    </table>
                </div>
            )
        })
    }
    <button className="button" onClick={() => {
        setValues.setLabour([...values.labour, {
            date: "",
            users: [{id: "", name: "", surname: "", hours: 0, minutes: 0}],
            vehicles: [{id: "", name: "", plate: "", hours: 0, minutes: 0}]
        }])
    }}>
        Aggiungi ulteriore giornata
    </button>
    </div>
    )
}