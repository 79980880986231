import React, {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../components/LoadingSpinner";
import LoadingError from "../components/LoadingError";
import {useUserInfo} from "../useUserInfo";

const Splash = () => {

    const [loadingError, setLoadingError] = useState(false);
    const { userInfo } = useUserInfo();
    const navigate = useNavigate();

    useEffect(() => {
        if (userInfo.isAuth === true) {
            navigate("/home");
        }
    }, [userInfo]);

    //Stay here until the user info is retrieved or, after 10 seconds, show an error
    useEffect(() => {
        setTimeout(() => {
            setLoadingError(true);
        }, 10000);
    }, []);

    return (
        loadingError ? <LoadingError /> : <LoadingSpinner />
    );
    }

export default Splash;