import "./VehicleStatus.css"
import {SemiCircleProgress} from "react-semicircle-progressbar";

const VehicleStatus = (props) => {

    const percentuage = props.percentuage
    console.log(percentuage)

    return (
        <div className="vehicleStatus">
                <SemiCircleProgress
                    percentage={percentuage}
                    size={{
                        width: 200,
                        height: 200,
                    }}
                    strokeWidth={10}
                    strokeColor="#007bff"
                    hasBackground={true}
                />
        </div>
    )
}

export default VehicleStatus;