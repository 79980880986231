import "./DailyHours.css"
import {useUserInfo} from "../../useUserInfo";
import toHtmlInputDate from "../functions/toHtmlInputDate";

export default function DailyHours(props) {

    const works = props.works
    const { userInfo } = useUserInfo();

    const laborHours = works.map(work => {
        return work.labour.map(labour => {
            return labour.users.map(user => {
                if ((user.id === userInfo.id || userInfo.isAdmin === 1) && toHtmlInputDate(new Date(labour.date)) === props.date) {
                    return {
                        name: user.name,
                        surname: user.surname,
                        hours: user.hours,
                        minutes: user.minutes
                    }
                }
            }).filter(Boolean)
        })
    })

    //Sommali tutti in ore e minuti
    const dailyHours = laborHours.flat(2).reduce((acc, val) => {
        const existing = acc.find(v => v.name === val.name && v.surname === val.surname);
        if (existing) {
            existing.hours += parseInt(val.hours) + Math.floor((existing.minutes + val.minutes) / 60)
            existing.minutes = (existing.minutes + val.minutes) % 60
        } else {
            acc.push(val);
        }
        return acc;
    }, []);

    //Order by hours and minutes
    dailyHours.sort((a, b) => {
        if (a.hours === b.hours) {
            return  b.minutes - a.minutes
        }
        return b.hours - a.hours
    })


    const percentuageCalculator = (hours, minutes) => {
        const percentuage = Math.round((hours + minutes / 60) / 8 * 100)
        if (percentuage > 100) {
            return 100
        }
        return percentuage
    }


    return (
        <div className="dailyHoursContainer">
            <h3>Riepilogo Giornaliero</h3>
            {dailyHours.length === 0 && <p >Nella giornata odierna non sono ancora stati effettuati lavori</p>}
            {dailyHours.map((dailyHour, index) => (
                <div key={index} className="usersHoursContainer">
                    <p>{dailyHour.name} {dailyHour.surname}</p>
                    <div className="loadingContainer">
                        <div className="loadingBar" style={{ width: `${percentuageCalculator(dailyHour.hours, dailyHour.minutes)}%`}}></div>
                    </div>
                    <p>{dailyHour.hours}h e {dailyHour.minutes} min</p>
                </div>
            ))}
        </div>
    )

}