const eventInputFieldChecker = (inputs) => {

    //Check form inputs
    if (inputs.title === "") {
        throw new Error("nome cliente vuoto")
    }

    if (inputs.beginning === "") {
        throw new Error("data inizio non valida")
    }

    if (inputs.ending === "") {
        throw new Error("data fine non valida")
    }

    // TABLES CHECK

    //Check notifications
    let validatedNotification = [...inputs.notifications]
    validatedNotification.pop()

    validatedNotification.forEach((notification) => {
        if (notification.userid === "") {
            throw new Error("notifica senza utente")
        }
        //subtract from the beginning the value multuplied by 60000 (1 minute)
        notification.timestamp = new Date(new Date(inputs.beginning).getTime() - parseInt(notification.time) * 60000)
        delete notification.time
    })

    return {
        title: inputs.title,
        description: inputs.description,
        beginning: new Date(inputs.beginning),
        ending: new Date(inputs.ending),
        typeId: inputs.typeId,
        notifications: validatedNotification
    }
}

export default eventInputFieldChecker