import "./PageSelector.css";
import {IoArrowBack, IoArrowForward} from "react-icons/io5";

export default function PageSelector ({page, setPage, numberOfPages}) {

    return (
        <div className="pageSelector">
            <button className="button" onClick={() => setPage(page - 1)} disabled={page === 0}><IoArrowBack /></button>
            <p>{page + 1}/{numberOfPages+1}</p>
            <button className="button" onClick={() => setPage(page + 1)} disabled={numberOfPages === page}><IoArrowForward /></button>
        </div>
    )

}