import NavBar from "../../components/NavBar";
import Calendar from "react-awesome-calendar";

import "../OnCallCalendar.css";
import {useEffect, useState} from "react";
import LoadingSpinner from "../../components/LoadingSpinner";
import LoadingError from "../../components/LoadingError";
import {useNavigate} from "react-router-dom";
import {LuCalendarPlus} from "react-icons/lu";
const moment = require('moment-timezone');

const OnCallCalendar = () => {

    const navigate = useNavigate()

    const [events, setEvents ] = useState([])

    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const [errorMessage, setErrorMessage] = useState('')

    /*
    const events = [{
        id: 1,
        color: '#fd3153',
        from: '2024-01-21T18:00:00+00:00',
        to: '2024-01-30T19:00:00+00:00',
        title: 'Reperibilità Stefano Pincolini'
    }, {
        id: 2,
        color: '#1ccb9e',
        from: '2019-05-01T13:00:00+00:00',
        to: '2023-05-05T14:00:00+00:00',
        title: 'This is another event'
    }, {
        id: 3,
        color: '#3694DF',
        from: '2019-05-05T13:00:00+00:00',
        to: '2019-05-05T20:00:00+00:00',
        title: 'This is also another event'
    }];

     */

    const colors = {
        1: '#fd3153',   //Reperibilità
        2: '#3694DF',   //Appuntamenti
        3: '#1ccb9e',   //Scadenze Mezzi
        4: '#f7b924',   //Ferie
    }

    const fetchEvents = async () => {
        try {
            const res = await fetch(process.env.REACT_APP_BACKEND_URL + '/calendar', {
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                }
            })

            let data = await res.json()

            //adapt data to the format of the calendar
            let events = data.events.map((event) => {
                console.log(moment(event.beginning).tz("Europe/Rome").format("YYYY-MM-DDTHH:mm:ss"))
                console.log(moment(event.ending).tz("Europe/Rome").format("YYYY-MM-DDTHH:mm:ss"))
                return {
                    id: event.id,
                    title: event.name,
                    color: colors[event.typeid],
                    //The function in calendar is broken with other fuse than +00:00
                    from: moment(event.beginning).tz("Europe/Rome").format("YYYY-MM-DDTHH:mm:ss+00:00"),
                    to: moment(event.ending).tz("Europe/Rome").format("YYYY-MM-DDTHH:mm:ss+00:00"),
                }
            })

            setEvents(events)
            setLoading(false)
        } catch (e) {
            console.log(e)
            setError(true)
            setErrorMessage(e.message)
        }
    }

    useEffect(() => {
        fetchEvents().then(() => {})
    }, []);

    return (
        (error) ? <LoadingError message={errorMessage} /> :
        (loading) ? <LoadingSpinner /> :
            <div className="mainContainer">
                <NavBar/>
                <Calendar
                    events={events}
                    onClickEvent={(event) => navigate(`/calendar/event/${event}`)}
                />
                <button className="button"
                        onClick={() =>
                            navigate('/calendar/add')}>
                    <LuCalendarPlus size={24} />
                    &nbsp;
                    Aggiungi
                </button>
            </div>
    )
}

export default OnCallCalendar