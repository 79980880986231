import {IoClose} from "react-icons/io5";
import React from "react";

export default function equipmentTable({values, setValues}) {

    return (
        <div style={{width: "100%", display: "flex", flexDirection: "column", alignItems: "center"}}>
            <h2>Attrezzature</h2>
            <table style={{width: "100%", maxWidth: "600px"}}>
                <thead>
                <tr>
                    <th>Nome</th>
                    <th>Ore</th>
                    <th>Minuti</th>
                    <th/>
                </tr>
                </thead>
                <tbody>
                {
                    values.equipments.map((val, idx) => {
                        let equipmentName = `equipment-${idx}`, hours = `hours-${idx}`,
                            minutes = `minutes-${idx}`
                        return (
                            <tr key={val.id}>
                                <td style={{paddingInline: "10px"}}>
                                    <select name="id" data-id={idx} id={equipmentName}
                                            className="form__field" style={{minWidth: 150}}
                                            value={val.id}
                                            defaultValue={""}
                                            onChange={(e) => {
                                                let newEquipments = [...values.equipments]
                                                newEquipments[idx].id = e.target.value
                                                setValues.setEquipments(newEquipments)
                                            }}
                                    >
                                        <option value={""}>Nessuna</option>
                                        {
                                            values.equipmentsList && values.equipmentsList.map((equipment) => {
                                                return (
                                                    <option
                                                        hidden={
                                                            // if the equipment is already selected, hide it from the options
                                                            values.equipments.filter((val) => parseInt(val.id) === parseInt(equipment.id)).length > 0
                                                        }
                                                        value={equipment.id}>{equipment.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </td>
                                <td style={{paddingInline: "10px", minWidth: 40}}>
                                    <input type="number" name="hours" data-id={idx} inputMode="numeric"
                                           id={hours}
                                           className="form__field" placeholder="Hours"
                                           style={window.innerWidth < 600 ? {maxWidth: 80} : {maxWidth: 120}}
                                           value={val.hours}
                                           onChange={(e) => {
                                               let newEquipments = [...values.equipments]
                                               newEquipments[idx].hours = e.target.value
                                               setValues.setEquipments(newEquipments)
                                           }}
                                    />
                                </td>

                                <td style={{paddingInline: "10px"}}>
                                    <select className="form__field"
                                            value={val.minutes}
                                            onChange={(e) => {
                                                let newEquipments = [...values.equipments]
                                                newEquipments[idx].minutes = e.target.value
                                                setValues.setEquipments(newEquipments)
                                            }}>
                                        <option value={0}>0</option>
                                        <option value={30}>30</option>
                                    </select>
                                </td>
                                <td style={{paddingInline: "0px"}}>
                                    <IoClose color={"grey"} size={24} style={{cursor: "pointer"}}
                                             onClick={() => {
                                                 if (values.equipments.length === 1) {
                                                     setValues.setEquipments([{
                                                         id: "",
                                                         name: "",
                                                         hours: 0,
                                                         minutes: 0
                                                     }])
                                                     return
                                                 }
                                                 let newEquipments = [...values.equipments]
                                                 // remove current row
                                                 newEquipments.splice(idx, 1)
                                                 setValues.setEquipments(newEquipments)
                                             }}/>
                                </td>
                            </tr>
                        )
                    })
                }
                </tbody>
            </table>
        </div>
    )

}