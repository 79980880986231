import LoadingError from "../../../../components/LoadingError";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import NavBar from "../../../../components/NavBar";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {IoClose} from "react-icons/io5";

import "./EventAdd.css"
import eventInputFieldChecker from "../../../../functions/eventInputFieldChecker";

const EventAdd = () => {

    const navigate = useNavigate()

    const [error, setError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [loading, setLoading] = useState(true)

    const [event, setEvent] = useState({
        title: "",
        description: "",
        typeId: 1,
        beginning: "",
        ending : "",
        notifications: [{
            userid: 0,
            time: "0"
        }]
    })

    const [types, setTypes] = useState([])
    const [users, setUsers] = useState([])

    const fetchTypes = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_BACKEND_URL + "/calendar/types", {
                credentials: "include",
                headers: {
                    "Content-Type": "application/json"
                }
            })

            if (response.status !== 200) {
                setError(true)
                setErrorMessage("Impossibile recuperare i tipi di evento")
                return
            }

            const data = await response.json()

            setTypes(data.types)
        } catch (e) {
            setError(true)
            setErrorMessage("Impossibile recuperare i tipi di evento")
        }
    }

    const fetchUsers = async () => {
        try {

            const response = await fetch(process.env.REACT_APP_BACKEND_URL + "/users", {
                credentials: "include",
                headers: {
                    "Content-Type": "application/json"
                }
            })

            if (response.status !== 200) {
                setErrorMessage("Impossibile recuperare gli utenti")
                setError(true)
                return
            }

            const data = await response.json()

            setUsers(data.users)
        } catch (e) {
            setError(true)
            setErrorMessage("Impossibile recuperare gli utenti")
        }

    }

    useEffect(() => {
        fetchTypes().then(() => {})
        fetchUsers().then(() => {})
    }, []);

    useEffect(() => {
        if (types.length > 0 && users.length > 0) {
            setLoading(false)
        }
    }, [types, users]);

    //Add line in notifications table if the last is filled
    useEffect(() => {
        if (event.notifications[event.notifications.length - 1].userid !== 0) {
            setEvent({...event, notifications: [...event.notifications, {userid: 0, time: "0"}]})
        }
    }, [event.notifications]);


    const saveEvent = async (e) => {
        e.preventDefault()
        setLoading(true)
        let validatedEvent
        try {
            validatedEvent = eventInputFieldChecker(event)
        } catch (e) {
            alert("Errore nel salvataggio dell'evento, nel dettaglio: " + e.message)
            return
        }

        try {

            const response = await fetch(process.env.REACT_APP_BACKEND_URL + "/calendar/add", {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({event: validatedEvent})
            })


            const data = await response.json()
            console.log(data)

            if (response.status !== 200) {
                setError(true)
                setErrorMessage("Impossibile salvare l'evento: nel dettaglio " + data.message)
                return
            }

            navigate("/calendar")

        } catch (e) {
            //setError(true)
            setErrorMessage("Impossibile salvare l'evento")
        }
    }


    return (
        (error) ? <LoadingError message={errorMessage} /> :
            (loading) ? <LoadingSpinner /> :
                <div className="mainContainer eventAdd">
                    <NavBar/>
                    <h1>Aggiungi Evento</h1>
                    <form>

                        <div className="form__group">
                            <input className="form__field" placeholder="Titolo"
                                   id="titleInput"
                                   required
                                   onChange={e =>
                                       setEvent({...event, title: e.target.value})
                                   }
                                   value={event.title}
                            />
                            <label htmlFor="titleInput" className="form__label">Titolo</label>
                        </div>

                        <div className="form__group">
                            <textarea className="form__field" id="description" placeholder="Descrizione"
                                      value={event.description}
                                      onChange={e =>
                                          setEvent({...event, description: e.target.value})
                                      }
                            />
                            <label className="form__label">Descrizione</label>
                        </div>

                        <div className="form__group">
                            <select name="type" className="form__field" placeholder="Tipo"
                                    value={event.typeId}
                                    onChange={e =>
                                        setEvent({...event, typeId: e.target.value})
                                    }
                            >
                                {types.map((type) => {
                                    return <option value={type.id}>{type.name}</option>
                                })}
                            </select>
                            <label className="form__label">Tipo</label>
                        </div>

                        <div className="form__group">
                            <input type="datetime-local" className="form__field" placeholder="Da"
                                   id="beginningInput" required
                                   onChange={e =>
                                       setEvent({...event, beginning: e.target.value})
                                   }
                                   value={event.beginning}
                            />
                            <label htmlFor="beginningInput" className="form__label">Inizio</label>
                        </div>

                        <div className="form__group">
                            <input type="datetime-local" className="form__field" placeholder="A"
                                   id="fromInput" required
                                   onChange={e =>
                                       setEvent({...event, ending: e.target.value})
                                   }
                                   value={event.ending}
                            />
                            <label htmlFor="fromInput" className="form__label">Fine</label>
                        </div>


                    </form>

                    <h2>Promemoria</h2>
                    <table style={{width: "100%", maxWidth: "600px"}}>
                        <thead>
                        <tr>
                            <th>Utente</th>
                            <th>Notifica</th>
                            <th/>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            event.notifications.map((val, idx) => {
                                let user = `user-${idx}`, time = `time-${idx}`
                                return (
                                    <tr key={val.index}>
                                        <td style={{paddingInline: "10px"}}>
                                            <select name="user" data-id={idx} id={user} className="form__field"
                                                    placeholder="Utente"
                                                    value={val.userid}
                                                    onChange={(e) => {
                                                        let newNotifications = [...event.notifications]
                                                        newNotifications[idx].userid = e.target.value
                                                        setEvent({...event, notifications: newNotifications})
                                                    }}
                                            >
                                                <option value="0">Seleziona</option>
                                                {users.map((user) => {
                                                    return <option value={user.id}>{user.name} {user.surname}</option>
                                                })}
                                            </select>
                                        </td>
                                        <td style={{paddingInline: "10px"}}>
                                            <select name="notificationScheduling" data-id={idx} id={user} className="form__field"
                                                    placeholder="notificationScheduling"
                                                    value={val.time}
                                                    onChange={(e) => {
                                                        let newNotifications = [...event.notifications]
                                                        newNotifications[idx].time = e.target.value
                                                        setEvent({...event, notifications: newNotifications})
                                                    }}
                                            >
                                                <option value="0">Al momento dell'evento</option>
                                                <option value="15">15 minuti prima</option>
                                                <option value="30">30 minuti prima</option>
                                                <option value="60">1 ora prima</option>
                                                <option value="120">2 ore prima</option>
                                                <option value="1440">1 giorno prima</option>
                                                <option value="2880">2 giorni prima</option>
                                                <option value="10080">1 settimana prima</option>


                                            </select>
                                        </td>
                                        <td style={{paddingInline: "0px"}}>
                                            <IoClose style={{cursor: "pointer"}}
                                                     onClick={() => {
                                                         let newNotifications = [...event.notifications]
                                                         newNotifications.splice(idx, 1)
                                                         setEvent({...event, notifications: newNotifications})
                                                     }}
                                            />
                                        </td>
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                    </table>

                    <button className="button" style={{margin: "30px"}} onClick={saveEvent}>
                        Salva
                    </button>


                </div>
    );
}

export default EventAdd;